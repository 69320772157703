import { Slot } from "@radix-ui/react-slot";
import { VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../utils";
import { tagInner, tagVariants } from "./tag.styles";

export interface TagProps extends VariantProps<typeof tagVariants> {
  children: React.ReactNode;
  asChild?: boolean;
  className?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  style?: React.CSSProperties;
}

export const Tag = React.forwardRef(
  (
    { asChild, size, variant, className, children, suffix, prefix, color, ...props }: TagProps,
    ref: React.Ref<HTMLSpanElement>,
  ) => {
    const Comp = asChild ? Slot : "span";
    const classes = cn(tagVariants({ size, variant, className, color }));
    return (
      <Comp className={classes} {...props} ref={ref}>
        <div className={tagInner()}>
          {prefix ? <span>{prefix}</span> : null}
          {children ? <span>{children}</span> : null}
          {suffix ? <span>{suffix}</span> : null}
        </div>
      </Comp>
    );
  },
);
